import isBlank from '@im-frontend/utils/isBlank';
import { FieldValidator } from 'final-form';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { useFormState } from 'react-final-form';
import { FieldsGroupContext } from '../FieldsGroup';
import { CheckboxField, TextField } from '.';

export interface FfcFieldValues {
  isFfc: boolean;
  ffcAccountNumber?: string;
  ffcCustodianName?: string;
}

const FfcCheckboxField = () => {
  const { submitting } = useFormState<FfcFieldValues>();

  return (
    <div data-cy="ffcCheckboxField">
      <CheckboxField
        rowClassName="mb-0 mt-4"
        name="isFfc"
        key="isFfc"
        label={'This is a Custodian/FBO Account'}
        disabled={submitting}
        style={{ pointerEvents: submitting ? 'none' : 'all' }}
      />
    </div>
  );
};

interface FfcTextFieldsProps {
  validate:
    | FieldValidator<string | number | readonly string[] | undefined>
    | undefined;
  required: boolean;
}

interface Props {
  ffcAccountNumberInputProps?: FfcTextFieldsProps;
  ffcCustodianNameInputProps?: FfcTextFieldsProps;
}

const FfcField = ({
  ffcCustodianNameInputProps,
  ffcAccountNumberInputProps,
}: Props) => {
  const {
    values,
    initialValues: formInitialValues,
    dirtyFields,
    submitting,
  } = useFormState<FfcFieldValues>();
  const { groupName } = useContext(FieldsGroupContext);
  const getGroupValue = (object: any) => {
    return groupName ? get(object, groupName) : object;
  };
  const formFfcInitialValues = getGroupValue(formInitialValues);
  const currentValues = getGroupValue(values);
  const hasInitialFfcFields = [
    formFfcInitialValues.ffcAccountNumber,
    formFfcInitialValues.ffcCustodianName,
  ].some(v => !isBlank(v));

  const ffcCheckboxIsDirty =
    dirtyFields[groupName ? `${groupName}.isFfc` : 'isFfc'];

  // Note: this does not behave well when formFfcInitialValues.isFfc is false
  const showFfcTextFields = ffcCheckboxIsDirty
    ? currentValues.isFfc
    : hasInitialFfcFields || currentValues.isFfc;

  return (
    <div data-cy="ffcField">
      {showFfcTextFields && (
        <>
          <TextField
            name={'ffcCustodianName'}
            key={'ffcCustodianName'}
            label={'Custodian Name'}
            initialValue={formFfcInitialValues.ffcCustodianName}
            disabled={submitting}
            {...ffcCustodianNameInputProps}
          />
          <TextField
            name={'ffcAccountNumber'}
            key={'ffcAccountNumber'}
            label={'For Benefit of: Account Number'}
            initialValue={formFfcInitialValues.ffcAccountNumber}
            disabled={submitting}
            {...ffcAccountNumberInputProps}
          />
        </>
      )}
    </div>
  );
};

export default FfcField;
export { FfcCheckboxField };
