import Big from 'big.js';
import React from 'react';
import useInternalValue from '../hooks/useInternalValue';
import NumberPatternInput, {
  NumberPatternInputProps,
} from './NumberPatternInput';

export type HighPrecisionNumberInputProps = NumberPatternInputProps;

export default function HighPrecisionNumberInput(
  props: HighPrecisionNumberInputProps
) {
  const [internalValue, onChangeOverride] = useInternalValue<
    string | null,
    string | null
  >(props.value, props.onChange, {
    internalToExternalValue: str => {
      // use bigjs for intelligent number parsing, then convert to string
      // handles cases like
      // '1.' => '1'
      // '1.0' => '1'
      // '.1' => '0.1'
      // '0.0000001' => '0.0000001' (not '1e-7')
      // eslint-disable-next-line rulesdir/report-errors-in-catch
      try {
        return str !== null ? Big(str).toFixed() : null;
      } catch (e) {
        return null;
      }
    },
    onChangeToInternalValue: e => e.target.value,
  });

  return (
    <NumberPatternInput
      allowNegative={false}
      integerPlaces={3}
      decimalPlaces={30}
      {...props}
      value={internalValue}
      onChange={onChangeOverride}
    />
  );
}
