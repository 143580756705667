// See #im-frontend if you're running into this firing for your feature
import { mustReportException } from '@im-frontend/utils/errors';
import { AxiosInstance, AxiosResponse } from 'axios';

// create a new error class for response type mismatch
export class ResponseTypeMismatchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ResponseTypeMismatchError';
  }
}

/**
 * File a Sentry report for requests that are likely to be rejected by AWS WAF.
 * Throw an exception to prevent the request from being sent.
 */
export default function enforceResponseTypeMatch(client: AxiosInstance) {
  client.interceptors.response.use((response: AxiosResponse) => {
    if (!response.headers['content-type'].includes('application/json')) {
      const error = new ResponseTypeMismatchError(
        `Response type does not match expected type: ${response.headers['content-type']} was not application/json`
      );
      mustReportException(error, {
        extra: {
          headers: response.headers,
          body: response.data,
          status: response.status,
        },
      });
      throw error;
    }
    return response;
  });
}
