import { Button, Datapair } from '@appfolio/react-gears';
import { SelectField } from '@im-frontend/forms/fields';
import Tooltip from '@im-frontend/utils/Tooltip';
import isBlank from '@im-frontend/utils/isBlank';
import withDefault from '@im-frontend/utils/withDefault';
import { get, keyBy } from 'lodash';
import React from 'react';
import { useFormState } from 'react-final-form';

export const AccountTypeValues = {
  personal_checking: 'Personal Checking',
  personal_savings: 'Personal Savings',
  commercial_checking: 'Business Checking',
  commercial_savings: 'Business Savings',
};
export type BankAccount = {
  id: number;
  routingNumber?: string | null;
  swiftCode?: string | null;
  accountNumberLast4: string;
  accountType?:
    | 'personal_checking'
    | 'personal_savings'
    | 'commercial_checking'
    | 'commercial_savings';
  bankName: string;
  nickname: string;
  owningEntity?: { id: number };
  owningContact?: { name: string };
};

export interface Props<T> {
  availableBankAccounts: T[];
  calculateOptionDisabledReason?: (
    bankAccount: T,
    values: any
  ) => string | null;
  addNewBankAccountOnClick: () => void;
  required?: boolean;
  label?: string;
  validate?: (value: number | null, values: any) => string | undefined;
  name: string;
  key: string;
  previewSelectedBankAccount?: boolean;
  disableAddNewBankAccount?: boolean;
}

export default function BankAccountSelectField<T extends BankAccount>({
  availableBankAccounts,
  addNewBankAccountOnClick,
  required,
  validate,
  label,
  name,
  key,
  disableAddNewBankAccount = false,
  calculateOptionDisabledReason,
  previewSelectedBankAccount = true,
}: Props<T>) {
  const { values, initialValues, dirtyFields } = useFormState();
  const options = availableBankAccounts.map(bankAccount => {
    const disabledReason = calculateOptionDisabledReason?.(bankAccount, values);
    const targetId = `bank-account-${bankAccount.id}-disabled-reason`;
    const labelKey = `${bankAccount.nickname}${
      bankAccount.owningContact?.name
        ? ` (Owned by ${bankAccount.owningContact.name})`
        : ''
    }`;
    return {
      value: bankAccount.id,
      labelKey: labelKey,
      label: (
        <div id={targetId}>
          <Tooltip
            tooltipText={disabledReason}
            targetId={targetId}
            placement="left-end"
            tooltipProps={{
              position: 'top',
            }}
          />
          {labelKey}
        </div>
      ),
      disabled: !isBlank(disabledReason) || false,
    };
  });
  const bankAccountsById = keyBy(availableBankAccounts, 'id');
  options.sort((a, b) => a.labelKey.localeCompare(b.labelKey));
  const initialValue = get(initialValues, name);
  const currentBankAccountId =
    get(values, name) || (dirtyFields?.[name] ? null : initialValue);
  const currentBankAccount = bankAccountsById[currentBankAccountId];

  return (
    <div data-test-id="bankAccountSelectField">
      <SelectField
        disableDefaultSort
        label={label || 'Bank Account'}
        initialValue={initialValue}
        name={name}
        filterOption={(option: { labelKey: string }, filter: string) => {
          const { labelKey } = option;
          return labelKey.toLowerCase().includes(filter.toLowerCase());
        }}
        key={key}
        required={required}
        validate={validate}
        options={options}
      />
      <Button
        color="link"
        className="pl-1 mt-n2 mb-3"
        onClick={addNewBankAccountOnClick}
        disabled={disableAddNewBankAccount}
      >
        {currentBankAccount?.owningEntity
          ? 'Update Bank Account'
          : 'Add New Bank Account'}
      </Button>
      {currentBankAccount && previewSelectedBankAccount && (
        <div data-test-id="bank-account-preview">
          <Datapair
            label="Bank Name"
            value={withDefault(currentBankAccount.bankName)}
          />
          <Datapair
            label="Account Number"
            value={withDefault(currentBankAccount.accountNumberLast4)}
          />
          <Datapair
            label="Routing Number"
            value={withDefault(currentBankAccount.routingNumber)}
          />
          <Datapair
            label="SWIFT Code"
            value={withDefault(currentBankAccount.swiftCode)}
          />
          <Datapair
            label="Account Type"
            value={withDefault(
              currentBankAccount.accountType
                ? AccountTypeValues[currentBankAccount.accountType]
                : null
            )}
          />
        </div>
      )}
    </div>
  );
}
