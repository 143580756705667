import CheckboxGroup, {
  CheckboxGroupProps,
} from '@appfolio/react-gears/lib/components/CheckboxGroup';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';

export type CheckboxGroupFieldProps = FormLabelGroupProps &
  CheckboxGroupProps & {
    name?: string;
    value: any[];
    tableField?: boolean;
  };

const CheckboxGroupField = ({
  required,
  hint,
  rowClassName,
  feedback,
  onChange,
  value,
  options,
  tableField,
}: CheckboxGroupFieldProps) => (
  <FormLabelGroup
    stacked
    required={required}
    hint={hint}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
  >
    <CheckboxGroup options={options} selected={value} onChange={onChange} />
  </FormLabelGroup>
);

export default CheckboxGroupField;
