import {
  FormLabelGroup,
  HasManyFieldsAdd,
  HasManyFieldsRow,
} from '@appfolio/react-gears';
import { FormLabelGroupProps } from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Tooltip from '@im-frontend/utils/Tooltip';
import { toJS } from 'mobx';
import React, { useContext } from 'react';
import { AnyObject } from 'react-final-form';
import {
  FieldArray,
  FieldArrayProps,
  FieldArrayRenderProps,
} from 'react-final-form-arrays';
import FieldsGroup, { FieldsGroupContext } from './FieldsGroup';
import useDeepMemoized from './useDeepMemoized';
import { RegisteredFieldProps, WrappedComponentProps } from './withField';

export interface HasManyFieldsRenderProps {
  name: string;
  index: number;
  disabled: boolean;
  templateExtraProps: AnyObject;
}

export type HasManyFieldsProps<
  FieldValue = unknown,
  BlankValue = FieldValue,
  ComponentProps extends Partial<WrappedComponentProps<FieldValue>> = AnyObject,
  TemplateExtraProps = AnyObject
> = FormLabelGroupProps & {
  addDisabled?: boolean;
  name: string;
  template: React.ComponentType<
    RegisteredFieldProps<FieldValue, ComponentProps>
  >;
  minimumRows?: number;
  maximumRows?: number;
  disabled?: boolean;
  blank?: BlankValue;
  addTooltip?: string;
  addText: string;
  onDelete?: (event: any, fields: any, index: any) => void;
  deleteLastOnly?: boolean;
  onAdd?: (
    fields: FieldArrayRenderProps<FieldValue, HTMLElement>['fields']
  ) => void;
  deletable?: (index: number) => boolean;
  templateExtraProps?: TemplateExtraProps;
} & FieldArrayProps<FieldValue[], HTMLElement>;

const getInitialValue = (minimumRows: any, blank: any) => {
  const initialValue = [];
  for (let i = 0; i < minimumRows; i++) {
    initialValue.push(blank);
  }
  return initialValue;
};

export default function HasManyFields<FieldValue>({
  name,
  template: Template,
  templateExtraProps,
  minimumRows = 1,
  maximumRows = 100,
  initialValue: initialValueProp,
  disabled,
  blank,
  label,
  addDisabled,
  addTooltip,
  required,
  rowClassName,
  addText,
  hint,
  onDelete,
  onlyLastItemIsEditable = false,
  deletable,
  onAdd = fields => fields.push(blank as FieldValue),
}: HasManyFieldsProps<FieldValue>) {
  const initialValue = useDeepMemoized(
    toJS(initialValueProp) || getInitialValue(minimumRows, blank)
  );
  const { groupName } = useContext(FieldsGroupContext);

  // FieldArray doesn't like names that start with a number, (some) symbols and spaces.
  // There should be an option to avoid validation and hence errors.
  // There seems to be a user of the form arrays that had a similar problem
  // https://github.com/final-form/react-final-form-arrays/issues/160
  // but this doesn't seem to work as expected.
  // Therefore, a solution is to check if the name of the FieldArray starts with
  // an invalid char or space, trim it and prefix it with an addmissible char.
  const currentName = groupName ? `${groupName}.${name}` : name;

  return (
    <FieldsGroup name="" reset>
      <FormLabelGroup
        stacked
        required={required}
        hint={hint}
        rowClassName={rowClassName}
        label={label}
      >
        <FieldArray<FieldValue> name={currentName} initialValue={initialValue}>
          {({ fields }) => {
            function isEditable(index: number) {
              const isLast = index + 1 === fields.length;

              if (onlyLastItemIsEditable && !isLast) {
                return false;
              }

              return true;
            }

            return (
              <>
                {fields.map((name, index) => (
                  <div key={name} data-cy="has-many-fields-row">
                    <HasManyFieldsRow
                      onDelete={event => {
                        onDelete
                          ? onDelete(event, fields, index)
                          : fields.remove(index);
                      }}
                      deletable={
                        deletable
                          ? deletable(index)
                          : isEditable(index) &&
                            (fields.length ?? 0) > minimumRows
                      }
                      disabled={disabled}
                    >
                      <Template
                        disabled={disabled || !isEditable(index)}
                        name={name}
                        index={index}
                        templateExtraProps={templateExtraProps}
                      />
                    </HasManyFieldsRow>
                  </div>
                ))}
                {maximumRows > (fields.length ?? 0) && (
                  <Tooltip tooltipText={addTooltip} placement="top" tag="span">
                    <HasManyFieldsAdd
                      className="has-many-fields-add"
                      onClick={() => onAdd(fields)}
                      disabled={addDisabled || disabled}
                    >
                      {addText}
                    </HasManyFieldsAdd>
                  </Tooltip>
                )}
              </>
            );
          }}
        </FieldArray>
      </FormLabelGroup>
    </FieldsGroup>
  );
}
