import Big from 'big.js';
import React from 'react';
import useInternalValue from '../hooks/useInternalValue';
import NumberPatternInput, {
  NumberPatternInputProps,
} from './NumberPatternInput';

export type NumberInputProps = NumberPatternInputProps;

export default function NumberInput(props: NumberInputProps) {
  const [internalValue, onChangeOverride] = useInternalValue<
    number | null,
    string | null
  >(props.value, props.onChange, {
    externalToInternalValue: num => {
      return num === null || typeof num === 'undefined' ? '' : num.toString();
    },
    internalToExternalValue: str => {
      // use bigjs for intelligent number parsing, then convert to plain js number
      // handles cases like
      // '1.' => 1
      // '1.0' => 1
      // '.1' => 0.1
      // eslint-disable-next-line rulesdir/report-errors-in-catch
      try {
        return str !== null ? +Big(str) : null;
      } catch (e) {
        return null;
      }
    },
    onChangeToInternalValue: e => e.target.value,
  });

  return (
    <NumberPatternInput
      allowNegative={false}
      integerPlaces={4}
      // limit to 6 because 0.0000001 gets converted to 1e-7 in JS...
      // can potentially increase the limit, but no need for now
      decimalPlaces={6}
      {...props}
      value={internalValue}
      onChange={onChangeOverride}
    />
  );
}
