import React from 'react';

export interface FieldsGroupInterface {
  name: string;
  children: any;
  onChange?: (() => Promise<void> | void) | null;
  reset?: boolean;
}

interface FieldsGroupContextInterface {
  groupName?: string;
  groupOnChange?: (() => Promise<void>) | null;
  onChange?: () => void;
}

export const FieldsGroupContext =
  React.createContext<FieldsGroupContextInterface>({});

const FieldsGroup: React.SFC<FieldsGroupInterface> = ({
  name,
  children,
  onChange,
  reset,
}) => (
  <FieldsGroupContext.Consumer>
    {({ groupName, groupOnChange }) => {
      const currentName = groupName && !reset ? `${groupName}.${name}` : name;

      const currentOnInputChange =
        onChange || groupOnChange
          ? async () => {
              if (typeof groupOnChange === 'function') {
                await groupOnChange();
              }
              if (typeof onChange === 'function') {
                await onChange();
              }
            }
          : null;
      return (
        <FieldsGroupContext.Provider
          value={{
            groupName: currentName,
            groupOnChange: currentOnInputChange,
          }}
        >
          {children}
        </FieldsGroupContext.Provider>
      );
    }}
  </FieldsGroupContext.Consumer>
);

export default FieldsGroup;
