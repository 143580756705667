import React, { PropsWithChildren } from 'react';

type RenderFunction<PropsType> = (
  props: PropsType,
  Component: React.ComponentType<PropsType>
) => React.ReactNode;

export interface RenderableProps<PropsType> {
  children?: RenderFunction<PropsType> | React.ReactNode;
  component?: React.ComponentType<PropsType>;
  render?: RenderFunction<PropsType>;
}

export default function getRenderer<PropsType>({
  render,
  component,
  children,
}: RenderableProps<PropsWithChildren<PropsType>>): RenderFunction<
  PropsWithChildren<PropsType>
> {
  if (component) {
    return props =>
      React.createElement(component as React.ComponentType<any>, props);
  }

  if (render) {
    return render;
  }

  if (typeof children === 'function') {
    return (props, Component) => {
      const { children: _throwAway, ...newProps } =
        props as PropsWithChildren<PropsType>;
      return children(newProps as PropsWithChildren<PropsType>, Component);
    };
  }

  return (props, Component) => (
    <Component {...(props as PropsWithChildren<PropsType>)} />
  );
}
